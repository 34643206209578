
import { Component, Prop } from 'vue-property-decorator'

import { UserMixin } from '../../shared'

import { ProfileViewConfig } from './ProfileView.config'

/**
 * @author Filip Rurak <filip.rurak@movecloser.pl>
 */
@Component({
  name: 'BaseProfileMixin'
})
export class BaseProfileMixin extends UserMixin {
  @Prop({ type: Object, required: true })
  public readonly config!: ProfileViewConfig
}

export default BaseProfileMixin
