import { AsyncComponent } from 'vue'

export enum AccountVariant {
  Default = 'default',
  Empty = 'empty'
}

/**
 * @author Filip Rurak <filip.rurak@movecloser.pl>
 */
export const ACCOUNT_VIEW_KEY = 'AccountView'

/**
 * @author Filip Rurak <filip.rurak@movecloser.pl>
 */
export interface AccountViewConfig {
  processingOrderStatus: string
  redirectFromAccountPage: string
  redirectOnDesktop: boolean
  ongoingShipmentStatus: string
  variant: AccountVariant
}

/**
 * @author Filip Rurak <filip.rurak@movecloser.pl>
 */
export const ACCOUNT_VIEW_CONFIG_MAP: AccountViewConfig = {
  processingOrderStatus: 'pendingPayment',
  ongoingShipmentStatus: 'complete',
  redirectFromAccountPage: '',
  redirectOnDesktop: false,
  variant: AccountVariant.Empty
}

export const accountVariantsRegistry: Record<AccountVariant, AsyncComponent> = {
  [AccountVariant.Default]: () => import(/* webpackChunkName: "account/AccountDefault" */ './variants/DefaultAccount.vue'),
  [AccountVariant.Empty]: () => import(/* webpackChunkName: "account/EmptyAccount" */ './variants/EmptyAccount.vue')
}
