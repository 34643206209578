/**
 * @author Filip Rurak <filip.rurak@movecloser.pl>
 */
import { ValidationRules } from '../../../shared/molecules/Form'

/**
 * @author Filip Rurak <filip.rurak@movecloser.pl>
 */
export const changeCompanyDataValidatorsMap: ValidationRules = {
  acceptContent: 'accepted',
  acceptPrivacy: 'accepted',
  adminEmail: 'required|string|email',
  adminFirstName: 'required|string',
  adminLastName: 'required|string',
  city: 'required|string',
  companyEmail: 'required|string|email',
  countryCode: 'required|string',
  legalName: 'required|string',
  companyName: 'required|string',
  postCode: 'required|string',
  region: 'required',
  vatTaxId: 'required|string',
  street: 'required|string',
  streetSecondLine: 'string',
  telephone: 'required|string'
}

/**
 * @author Filip Rurak <filip.rurak@movecloser.pl>
 */
export const changeCompanyAdministratorDataValidationMap: ValidationRules = {
  adminFirstName: 'required|string',
  adminLastName: 'required|string',
  acceptContent: 'accepted',
  acceptPrivacy: 'accepted'
}
