









































import { Component, Mixins, Prop } from 'vue-property-decorator'

import { FormErrorsMixin } from '../../../../support/mixins'
import { Inject } from '../../../../support'

import { ResetPasswordDataPayload } from '../../../../contexts'
import { SimpleForm } from '../../../shared/molecules/Form'
import { ToastType } from '../../../shared/services'

import { IProfileService, ProfileServiceType } from '../../contracts'
import { changePasswordFormValidatorsMap } from './ChangePasswordForm.helpers'

@Component<ChangePasswordForm>({
  name: 'ChangePasswordForm',
  components: { SimpleForm }
})
export class ChangePasswordForm extends Mixins(FormErrorsMixin) {
  @Inject(ProfileServiceType)
  protected readonly profileRepository!: IProfileService

  @Prop({ type: String, required: false, default: null })
  public formHeadingProp?: string

  @Prop({ type: String, required: false, default: null })
  public formEditLabelProp?: string

  /**
   * Submitted form data
   */
  public formData: ResetPasswordDataPayload = { password: '', newPassword: '' }

  /**
   * Validators map
   */
  public readonly validatorsMap = changePasswordFormValidatorsMap

  public get simpleFormHeading (): string {
    if (this.formHeadingProp) {
      return this.formHeadingProp.toString()
    }

    return this.$t('front.profile.organisms.changePasswordForm.label').toString()
  }

  public onFail (error: unknown): void {
    this.setError(error as Error)
  }

  public async onSubmit (): Promise<void> {
    await this.profileRepository.changePassword(this.formData)
  }

  public onSuccess (): void {
    this.showToast(
      this.$t('front._common.saved').toString(),
      ToastType.Success
    )
  }
}

export default ChangePasswordForm
