

















































import { Component, Mixins, Watch } from 'vue-property-decorator'

import {
  AnyObject,
  Authentication,
  AuthServiceType,
  EventbusType,
  IEventbus
} from '@movecloser/front-core'

import FormErrorsMixin from '../../../../support/mixins/FormErrors.mixin.vue'
import { Inject, logger } from '../../../../support'
import { ISiteService, SiteServiceType } from '../../../../contexts'
import { StructureConfigurable } from '../../../../support/mixins'

import Consents from '../../../shared/molecules/Consents/Consents.vue'
import { Form, ValidationRules } from '../../../shared/molecules/Form'
import {
  INipValidationService,
  NipValidationServiceType
} from '../../../shared/services/nip-validation'
import { IProfileService, ProfileServiceType } from '../../../profile/contracts'
import { FormContextSwitchContracts } from '../../../checkout/molecules/FormContextSwitch/FormContextSwitch.contracts'
import RadioSwitch from '../../../checkout/molecules/FormContextSwitch/RadioSwitch.vue'
import { RouteNames as ProfileRoutes } from '../../../profile/routes'
import { UserMixin } from '../../../profile/shared'

import { AuthControlServiceType, IAuthControl } from '../../contracts'
import {
  authSSOCustomerData,
  authSSOParamsKey,
  authSSOSelectedProvider,
  authSSOSessionId,
  authSSOSource
} from '../../config/auth'
import { UserModel } from '../../shared'

import { ConsentsRequestMode } from './ConsentsRequest.contracts'
import {
  CONSENTS_REQUEST_VIEW_CONFIG_MAP,
  CONSENTS_REQUEST_VIEW_KEY
} from './ConsentsRequest.config'

/**
 * @author Filip Rurak <filip.rurak@movecloser.pl>
 */
@Component<ConsentsRequest>({
  name: 'ConsentsRequest',
  components: { Consents, Form, RadioSwitch },
  created () {
    this.config = this.getComponentConfig(CONSENTS_REQUEST_VIEW_KEY, { ...CONSENTS_REQUEST_VIEW_CONFIG_MAP })
    this.composeFormContexts()
    this.composeConsentsPayload()
    this.composeFormData()
  }
})
export class ConsentsRequest extends Mixins(FormErrorsMixin, StructureConfigurable, UserMixin) {
  @Inject(AuthControlServiceType)
  protected readonly authControl!: IAuthControl

  @Inject(AuthServiceType)
  protected readonly authService!: Authentication<UserModel>

  @Inject(EventbusType)
  protected readonly eventBus!: IEventbus

  @Inject(NipValidationServiceType)
  protected readonly nipValidationService!: INipValidationService

  @Inject(ProfileServiceType)
  protected readonly profileService!: IProfileService

  @Inject(SiteServiceType)
  public readonly siteService!: ISiteService

  public config!: AnyObject
  public consentsPayload: Record<string, boolean> | null = null
  public formData: AnyObject | null = null
  public mode: ConsentsRequestMode = ConsentsRequestMode.Guest
  public formModes = ConsentsRequestMode
  public formContexts: FormContextSwitchContracts | null = null

  @Watch('mode')
  private onModeUpdate (): void {
    this.composeFormData()
  }

  /**
   * Determines the consents of form.
   */
  public get consentsConfig (): AnyObject {
    return this.getConfigProperty<AnyObject>('consents')
  }

  public get displaySwitch (): boolean {
    return this.hasResellerIdField && !!this.formContexts?.radio && this.formContexts.radio.length > 1
  }

  /**
   * Determines whether form should require resellerId field
   */
  public get hasResellerIdField (): boolean {
    return this.getConfigProperty<boolean>('hasResellerIdField')
  }

  /**
   * Determines validatorsMap for context (guest / company)
   */
  public get validatorsMapConfig (): Record<string, ValidationRules> | null {
    return this.getConfigProperty<Record<string, ValidationRules> | null>('validatorsMap')
  }

  /**
   * Returns validators map based on context (guest / company)
   */
  public get validatorsMap (): ValidationRules | undefined {
    if (
      !this.validatorsMapConfig ||
      !Object.prototype.hasOwnProperty.call(this.validatorsMapConfig, 'guest') ||
      !Object.prototype.hasOwnProperty.call(this.validatorsMapConfig, 'company')
    ) {
      return
    }

    if (this.hasResellerIdField && this.mode === ConsentsRequestMode.Company) {
      return this.validatorsMapConfig.company
    } else {
      return this.validatorsMapConfig.guest
    }
  }

  /**
   * Retrieves a site logo
   */
  public get siteData () {
    return {
      logo: this.siteService.getActiveSite().logo.light,
      name: this.siteService.getActiveSite().name
    }
  }

  public onFail (error: Error): void {
    this.setError(error)
  }

  /**
   * Handles consents acceptance action
   */
  public async submitForm (): Promise<void> {
    const SSOParams = localStorage.getItem(authSSOParamsKey)
    const selectedProvider = localStorage.getItem(authSSOSelectedProvider)
    const SSOCustomer = localStorage.getItem(authSSOCustomerData)
    const sessionId = localStorage.getItem(authSSOSessionId)

    if (!SSOParams || !selectedProvider || !sessionId || !this.formData) {
      return
    }

    if (this.hasResellerIdField && this.mode === ConsentsRequestMode.Company) {
      const validationResult = await this.validateResellerId(this.formData.vatTaxId)

      if (validationResult.errorMessage) {
        this.showToast(validationResult.errorMessage, 'danger')
        return
      }
    }

    try {
      const response = await this.authControl.setSocialCallback(
        JSON.parse(SSOParams),
        selectedProvider,
        JSON.parse(sessionId).id,
        true,
        this.mode === ConsentsRequestMode.Company ? this.formData.vatTaxId : undefined
      )

      if (response && response.token && response.token.accessToken) {
        /** Set token for authorization */
        this.authService.setToken(response.token.toAuthToken())

        const customerData = SSOCustomer ? JSON.parse(SSOCustomer) : null
        const userPayload = {
          email: customerData?.email ?? null,
          firstName: customerData.name ? customerData.name.split(' ')[0] : null,
          lastName: customerData.name ? customerData.name.split(' ')[1] : null
        }

        this.eventBus.emit(
          'app:authorization.sign_up',
          {
            ...userPayload,
            emailOffers: this.formData ? this.formData.emailOffers : false,
            smsOffers: this.formData ? this.formData.smsOffers : false
          }
        )

        if (this.formData.emailOffers || this.formData.smsOffers) {
          this.eventBus.emit('app:newsletter.consents', {
            ...userPayload,
            emailOffers: this.formData.emailOffers,
            smsOffers: this.formData.smsOffers
          })
        }

        await this.$router.push({
          name: `profile.${ProfileRoutes.Profile}`,
          params: { triggerEvent: 'login' }
        })

        /**
         * @inheritDoc
         */
        ConsentsRequest.RemoveSSOStorageKeys()
      }
    } catch (e) {
      this.showToast((e as Error).message, 'danger')
      logger(e, 'warn')
    }
  }

  protected composeConsentsPayload () {
    for (const option of Object.values(this.consentsConfig)) {
      this.consentsPayload = {
        ...this.consentsPayload,
        [option.option]: false
      }
    }
  }

  protected composeFormContexts () {
    let hasGuest = true
    if (this.siteService) {
      const settings = this.siteService.getProperty('settings') as AnyObject | null

      hasGuest = !settings?.onlyCompany

      if (!hasGuest) {
        this.mode = ConsentsRequestMode.Company
      }
    }

    this.formContexts = {
      radio: [
        ...(hasGuest ? [{
          name: 'customerContext',
          label: ConsentsRequestMode.Guest,
          value: ConsentsRequestMode.Guest,
          id: ConsentsRequestMode.Guest
        }] : []),
        {
          name: 'customerContext',
          label: ConsentsRequestMode.Company,
          value: ConsentsRequestMode.Company,
          id: ConsentsRequestMode.Company
        }
      ]
    }
  }

  /**
   * Composes formData based on current form mode (guest / company)
   */
  protected composeFormData (): void {
    switch (this.mode) {
      case ConsentsRequestMode.Company:
        this.formData = {
          vatTaxId: '',
          ...this.consentsPayload
        }
        break
      case ConsentsRequestMode.Guest:
        this.formData = {
          ...this.consentsPayload
        }
        break
    }
  }

  /**
   * Validated resellerId with CEIDG database
   * @private
   */
  private async validateResellerId (vatTaxId: string): Promise<{ errorMessage: string | null }> {
    try {
      await this.nipValidationService.validateCompanyVatId(vatTaxId)
      return { errorMessage: null }
    } catch (e) {
      logger(e, 'warn')
      return { errorMessage: (e as Error).message }
    }
  }

  /**
   * Handles removal of all storage items that were used while authentication
   */
  private static RemoveSSOStorageKeys (): void {
    localStorage.removeItem(authSSOParamsKey)
    localStorage.removeItem(authSSOSelectedProvider)
    localStorage.removeItem(authSSOCustomerData)
    localStorage.removeItem(authSSOSessionId)
    localStorage.removeItem(authSSOSource)
  }
}

export default ConsentsRequest
