







































import { Component, Mixins, Prop, Watch } from 'vue-property-decorator'

import { Inject } from '../../../../support'
import { FormErrorsMixin } from '../../../../support/mixins'

import { AuthMixin, UserModel } from '../../../auth/shared'

import { Loader } from '../../../shared/molecules/Loader'
import { SimpleForm } from '../../../shared/molecules/Form'
import { ToastType } from '../../../shared/services'
import { UserMixin } from '../../shared'

import { IProfileService, ProfileServiceType } from '../../contracts'

import { ChangeEmailFormData } from './ChangeEmailForm.contracts'
import { changeEmailFormValidatorsMap } from './ChangeEmailForm.form'
import { EventbusType, IEventbus } from '@movecloser/front-core'

/**
 * @author Wojciech Falkowski <wojciech.falkowski@movecloser.pl>
 */
@Component<ChangeEmailForm>({
  name: 'ChangeEmailForm',
  components: { SimpleForm, Loader },
  created (): void {
    if (!this.isLoggedInUser) {
      return
    }

    this.loadContactDetails()
  }
})
export class ChangeEmailForm extends Mixins(FormErrorsMixin, AuthMixin, UserMixin) {
  @Inject(ProfileServiceType)
  protected readonly profileRepository!: IProfileService

  @Inject(EventbusType)
  protected readonly eventBus!: IEventbus

  @Prop({ type: String, required: false, default: null })
  public formHeadingProp?: string

  @Prop({ type: String, required: false, default: null })
  public formEditLabelProp?: string

  /**
   * Validators map.
   */
  public readonly validatorsMap = changeEmailFormValidatorsMap

  /**
   * Forms payload.
   */
  public formData: ChangeEmailFormData = {
    email: '',
    password: ''
  }

  public initialEmail: string = ''

  public isLoading: boolean = true

  public get simpleFormHeading (): string {
    if (this.formHeadingProp) {
      return this.formHeadingProp.toString()
    }

    return this.$t('front.profile.organisms.changeEmailForm.contactData').toString()
  }

  /**
   * Submits the form.
   */
  public async onSubmit (): Promise<UserModel | null> {
    this.error = ''

    if (this.initialEmail === this.formData.email) {
      const error = this.$t('front.profile.organisms.changeEmailForm.sameEmail').toString()
      this.error = error
      throw new Error(error)
    }

    return await this.profileRepository.changeEmail(this.formData)
  }

  public loadContactDetails () {
    this.formData.email = this.user?.email ?? ''
    this.initialEmail = this.user?.email ?? ''
    this.isLoading = false
  }

  /**
   * Handles @fail event of form.
   */
  public onFail (error: Error): void {
    this.setError(error)
  }

  /**
   * Handles @success event of form.
   */
  public onSuccess (userModel: UserModel): void {
    this.setUser(userModel)

    this.eventBus.emit('app:user.change_data', userModel)

    this.showToast(
      this.$t('front._common.saved').toString(),
      ToastType.Success
    )
  }

  @Watch('isLoggedInUser')
  public onWaitingForAuth () {
    this.loadContactDetails()
  }
}

export default ChangeEmailForm
