import {
  ChangeSubscriptionsPreferencesFormConfig,
  FormDataFieldType, SubscriptionsProvider
} from './ChangeSubscriptionsPreferencesForm.contracts'

/**
 * @author Filip Rurak <filip.rurak@movecloser.pl>
 */
export const CHANGE_SUBSCRIPTIONS_FORM_KEY = 'ChangeSubscriptionsPreferencesForm'

/**
 * @author Filip Rurak <filip.rurak@movecloser.pl>
 */
export const CHANGE_SUBSCRIPTIONS_FORM_CONFIG: ChangeSubscriptionsPreferencesFormConfig = {
  formData: {
    isNewsletterSubscribed: {
      id: 'isNewsletterSubscribed',
      isRequired: false,
      defaultValue: false,
      fieldType: FormDataFieldType.UiCheck
    },
    isSmsSubscribed: {
      id: 'isSmsSubscribed',
      isRequired: false,
      defaultValue: false,
      fieldType: FormDataFieldType.UiCheck
    }
  },
  provider: SubscriptionsProvider.UserCom
}
