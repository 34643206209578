











































import { Component, Mixins, Prop, Watch } from 'vue-property-decorator'
import { AnyObject, Authentication, AuthServiceType } from '@movecloser/front-core'
import { TranslateResult } from 'vue-i18n'

import {
  Country,
  DeliveryAddressData,
  ISiteService,
  SiteServiceType
} from '../../../../contexts'

import { Inject } from '../../../../support'
import { FormErrorsMixin } from '../../../../support/mixins'

import { AuthMixin, UserModel } from '../../../auth/shared'

import {
  DictionaryServiceType,
  IDictionaryService
} from '../../../shared/services/dictionary'
import { Loader } from '../../../shared/molecules/Loader'
import { SimpleForm } from '../../../shared/molecules/Form'
import { ToastMixin } from '../../../shared'
import { ToastType } from '../../../shared/services'
import { UserMixin } from '../../shared'
import { validatePayload } from '../../../shared/support/validate-payload'

import { ChangeAddressFormFields } from '../../molecules/ChangeAddressFormFields'
import { SavedAddressesFormFields } from '../../molecules/SavedAddressesFormFields'
import { IProfileService, ProfileServiceType } from '../../contracts'

import {
  changeAddressFormValidators,
  translateCountryToCountrySelectOption
} from './ChangeAddressForm.helpers'
import { DeliveryAddressFormData } from './ChangeAddressForm.contracts'

/**
 * @author Javlon Khalimjonov <javlon.khalimjonov@movecloser.pl> (original)
 * @author Wojciech Falkowski <wojciech.falkowski@movecloser.pl> (edited)
 */
@Component<ChangeAddressForm>({
  name: 'ChangeAddressForm',
  components: { ChangeAddressFormFields, Loader, SavedAddressesFormFields, SimpleForm },
  created (): void {
    this.loadUserData().then(() => {
      this.fetchAddresses()
    })
  },
  mounted (): void {
    if (this.hasRegionField) {
      this.fillFormDataWithRegionField()
    }
  }
})
export class ChangeAddressForm extends Mixins(AuthMixin, FormErrorsMixin, UserMixin, ToastMixin) {
  @Inject(AuthServiceType)
  protected readonly authService!: Authentication<UserModel>

  @Inject(DictionaryServiceType)
  protected readonly dictionaryService!: IDictionaryService

  @Inject(ProfileServiceType)
  protected readonly profileService!: IProfileService

  @Inject(SiteServiceType)
  protected readonly siteService!: ISiteService

  @Prop({ type: String, required: false, default: null })
  public headingProp?: string

  @Prop({ type: Boolean, required: false, default: false })
  public hasRegionField?: boolean

  @Prop({ type: Boolean, required: false, default: false })
  public isAlwaysCompany?: boolean

  @Prop({ type: Boolean, required: false, default: false })
  public alwaysInEditMode!: boolean

  @Prop({ type: Boolean, required: false, default: false })
  public hideLabelOnEdit!: boolean

  @Prop({ type: Boolean, required: false, default: true })
  public showCompanyFields!: boolean

  public addresses: DeliveryAddressData[] = []

  public editingAddressIndex: null | number = null

  public formData: DeliveryAddressFormData = {
    company: '',
    countryCode: '',
    city: '',
    firstName: '',
    lastName: '',
    postalCode: '',
    phoneNumber: '',
    street: ''
  }

  public isEditMode: boolean = false
  public isFormVisible: boolean = false
  public isFormLoading: boolean = false
  public isLoading: boolean = false
  public isLegendVisible: boolean = true

  public selectedAddress: number | null = null

  public get countries (): Array<Country> {
    return translateCountryToCountrySelectOption(this.dictionaryService.countries)
  }

  /**
   * Gets first delivery address of the user.
   */
  public get address (): DeliveryAddressData {
    return this.addresses[0]
  }

  /**
   * Determines whether user has delivery addresses.
   */
  public get hasAddresses (): boolean {
    return Array.isArray(this.addresses) && this.addresses.length > 0
  }

  /**
   * Adds delivery address.
   */
  public async addAddress (): Promise<void> {
    this.isSubmitted = null
    this.error = ''

    const formData = {
      ...this.formData,
      defaultBilling: false,
      defaultShipping: false
    }

    try {
      await this.profileService.storeDeliveryAddress(formData)
    } catch (e) {
      throw new Error((e as Error).message)
    }
  }

  public onAddressEdit (id: number): void {
    const candidate = this.addresses.filter((address) => address.id === id)
    this.editingAddressIndex = id
    if (Array.isArray(candidate) && candidate.length > 0) {
      const addressToEdit = candidate[0]

      this.formData = {
        ...addressToEdit
      }
    }

    this.isLegendVisible = !this.hideLabelOnEdit
    this.isEditMode = true
    this.toggleFormVisibility()
  }

  public cancelEditMode (): void {
    this.isEditMode = false
    this.isLegendVisible = true
    this.toggleFormVisibility()
  }

  public async submit (): Promise<void> {
    if (!ChangeAddressForm.isValidStep(
      this.formData,
      this.$t.bind(this),
      this.$i18n.locale,
      this.onErrors
    )) {
      return
    }

    this.isFormLoading = true

    try {
      if (this.isEditMode) {
        await this.updateAddress()
      } else {
        await this.addAddress()
      }

      this.isEditMode = false
      this.isLegendVisible = true

      this.showToast(this.$t('front._common.saved').toString(), ToastType.Success)

      this.loadUserData().then(() => {
        this.fetchAddresses()
      })

      this.isFormVisible = false
    } catch (e) {
      this.showToast((e as Error).message, 'danger')
    } finally {
      this.isFormLoading = false
    }
  }

  public toggleFormVisibility (): void {
    this.isFormVisible = !this.isFormVisible
  }

  /**
   * Loads user data.
   * @protected
   */
  protected async loadUserData (): Promise<void> {
    try {
      if (!this.authService.token) {
        return
      }

      const userData = await this.profileService.getUserDetails(this.authService.token.accessToken)

      this.setUser(userData)
      this.authService.setUser(userData)
    } catch (e) {
      this.showToast((e as Error).message, 'danger')
    }
  }

  @Watch('hasAddresses')
  protected onAddresses (value: boolean): void {
    if (value) {
      this.formData = {
        ...this.formData,
        ...this.address
      }

      if (this.hasRegionField && !Object.prototype.hasOwnProperty.call(this.formData, 'region')) {
        this.formData = {
          ...this.formData,
          region: ''
        }
      }
    }
  }

  @Watch('isLoggedInUser')
  protected async onWaitingForAuth () {
    this.fetchAddresses()
  }

  /**
   * Fetches delivery addresses
   */
  private fetchAddresses (): void {
    this.isLoading = true

    try {
      if (!this.user || this.user.addresses.length === 0) {
        return
      }

      this.addresses = [...this.user.addresses]
      // TODO: After launch (MENA) redo. (AddressSelector for Billing and Shipping will be separate)
      this.selectedAddress = this.user.addresses.filter((address) => address.defaultShipping && address.defaultBilling)[0].id ?? null
    } catch (e) {
      this.setError(e as Error)
    } finally {
      this.isLoading = false
    }
  }

  private fillFormDataWithRegionField (): void {
    this.formData = {
      ...this.formData,
      region: ''
    }
  }

  private async updateAddress (): Promise<void> {
    if (!this.editingAddressIndex) {
      return
    }

    await this.profileService.updateDeliveryAddress(this.editingAddressIndex, { ...this.formData })
  }

  /**
   * Validates the payload
   */
  private static isValidStep (
    payload: AnyObject,
    translate: (key: string) => TranslateResult,
    locale: string,
    setErrors?: (errors: Record<string, string[]>) => void
  ): boolean {
    const result = validatePayload(
      payload,
      changeAddressFormValidators,
      translate,
      locale
    )

    if (result.errors && setErrors) {
      setErrors(result.errors)
    }

    return !result.errors || Object.keys(result.errors).length === 0
  }
}

export default ChangeAddressForm
