
import {
  ContextSwitchComponent,
  FormContextSwitchContracts
} from '../../../checkout/molecules/FormContextSwitch/FormContextSwitch.contracts'

/**
 * @author Filip Rurak <filip.rurak@movecloser.pl>
 */
export const AUTH_VIEW_KEY = 'AuthView'

/**
 * @author Filip Rurak <filip.rurak@movecloser.pl>
 */
export interface AuthViewConfig {
  loginRegisterContextProps: FormContextSwitchContracts
  loginRegisterContext: string
}

/**
 * @author Filip Rurak <filip.rurak@movecloser.pl>
 */
export const AUTH_VIEW_CONFIG_MAP: AuthViewConfig = {
  loginRegisterContextProps: {
    radio: [
      {
        class: ['w-100'],
        label: 'login',
        value: 'login',
        id: 'login',
        name: 'mode'
      },
      {
        class: ['w-100'],
        label: 'register',
        value: 'register',
        id: 'register',
        name: 'mode'
      }
    ]
  },
  loginRegisterContext: ContextSwitchComponent.RadioSwitch
}
