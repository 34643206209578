
import { Component, Mixins, Watch } from 'vue-property-decorator'

import { Inject, logger } from '../../../support'

import { AuthMixin } from '../../auth/shared'
import { CompanyModel } from '../../profile/contracts/models'
import { CompanyRepositoryType, ICompanyRepository } from '../../profile/repositories/company'

import { ICompany } from '../contracts/company'
import BaseCompany from './base-company.mixin'

/**
 * @author Filip Rurak <filip.rurak@movecloser.pl>
 */
@Component<CompanyMixin>({
  name: 'CompanyMixin'
})
export class CompanyMixin extends Mixins(AuthMixin, BaseCompany) implements ICompany {
  @Inject(CompanyRepositoryType)
  protected readonly companyRepository!: ICompanyRepository

  protected companyData: CompanyModel | null = null
  protected companyFetchFailed: boolean = false
  protected isLoading: boolean = false

  public get company (): CompanyModel | null {
    return this.$store.getters['shared/getCompany']
  }

  public get hasCompanyFetchFailed (): boolean {
    return this.companyFetchFailed
  }

  /**
   * Retrieves company data
   */
  public async fetchCompanyData (): Promise<void> {
    if (!this.isLoggedInUser) {
      this.$store.commit('shared/setCompany', null)
      return
    }

    this.isLoading = true

    try {
      const company = await this.companyRepository.getCompanyData()
      if (company) {
        this.companyData = company
        this.$store.commit('shared/setCompany', company)
      }
    } catch (e) {
      logger(e, 'warn')
      this.companyFetchFailed = true
      this.$store.commit('shared/setCompany', null)
    } finally {
      this.isLoading = false
    }
  }

  @Watch('isLoggedInUser')
  private async onIsLoggedInUpdate (): Promise<void> {
    await this.fetchCompanyData()
  }
}

export default CompanyMixin
