
import {
  CreateCompanyFieldsetPayload
} from '../../../auth/organisms/CreateCompanyFieldset/CreateCompanyFieldset.contracts'
import { ValidationRules } from '../../../shared/molecules/Form'

/**
 * @author Filip Rurak <filip.rurak@movecloser.pl>
 */
export enum RequestCompanyMode {
  ExistingCompany = 'existingCompany',
  NewCompany = 'newCompany'
}

/**
 * @author Filip Rurak <filip.rurak@movecloser.pl>
 */
export interface ExistingCompanyRequestPayload {
  email: string
  vatTaxId: string
}

/**
 * @author Filip Rurak <filip.rurak@movecloser.pl>
 */
export type NewCompanyRequestPayload = CreateCompanyFieldsetPayload

/**
 * @author Filip Rurak <filip.rurak@movecloser.pl>
 */
export interface RequestCompanyFormConfig {
  validatorsMap: Record<RequestCompanyMode, ValidationRules> | null
}
