

























































import { Component, Inject as VueInject, Mixins } from 'vue-property-decorator'
import { RawLocation } from 'vue-router'

import { defaultProvider, Inject, IS_MOBILE_PROVIDER_KEY } from '../../../support'
import { FormErrorsMixin, StructureConfigurable } from '../../../support/mixins'
import { ISiteService, SiteServiceType } from '../../../contexts'

import { Form } from '../../shared/molecules/Form'
import { ToastMixin } from '../../shared'

import { AuthControlServiceType, IAuthControl } from '../contracts'
import {
  RequestResetLinkFormData,
  requestResetLinkValidationsMap
} from '../organisms/RequestResetLinkForm'
import { RouteNames } from '../routes'
import { AnyObject } from '@movecloser/front-core'
import {
  defaultComponentConfig,
  REQUEST_RESET_LINK_FORM_COMPONENT_KEY
} from '../organisms/RequestResetLinkForm/RequestResetLinkForm.config'

/**
 * @author Javlon Khalimjonov <javlon.khalimjonov@movecloser.pl>
 */
@Component<RequestResetPassword>({
  name: 'RequestResetPassword',
  components: { Form },
  mounted (): void {
    this.brandImage = this.siteService.getActiveSite().logo.light

    this.config = this.getComponentConfig(REQUEST_RESET_LINK_FORM_COMPONENT_KEY, defaultComponentConfig)
  }
})
export class RequestResetPassword extends Mixins(FormErrorsMixin, ToastMixin, StructureConfigurable) {
  @VueInject({ from: IS_MOBILE_PROVIDER_KEY, default: () => defaultProvider<boolean>(false) })
  public readonly isMobile!: () => boolean

  @Inject(AuthControlServiceType)
  protected readonly authControl!: IAuthControl

  @Inject(SiteServiceType)
  protected readonly siteService!: ISiteService

  protected validatorsMap = requestResetLinkValidationsMap

  public brandImage: string = ''

  /**
   * Default values for formData.
   */
  public formData: RequestResetLinkFormData = {
    email: ''
  }

  public isSent: boolean = false

  public get isAlertUnderForm (): AnyObject {
    return this.getConfigProperty<AnyObject>('isAlertUnderForm')
  }

  public get buttonVariant (): string {
    return this.getConfigProperty('buttonVariant')
  }

  /**
   * Determines the possible redirect link after successful action.
   */
  public get goToOnSuccessLink (): RawLocation {
    return { name: `auth.${RouteNames.Auth}` }
  }

  public async resetLink (): Promise<void | boolean> {
    this.error = null

    return this.authControl.requestResetLink(this.formData)
  }

  /**
   * Handles @success event of `Form`
   */
  public onSuccess (): void {
    this.showToast(this.$t('front.auth.organisms.requestResetLinkForm.success')
      .toString(), 'success')
    this.$emit('onSuccess')
    this.isSent = true
  }

  /**
   * Handles @fail event of `Form`
   */
  public onFail (error: any): void {
    this.setError(error)
  }
}

export default RequestResetPassword
