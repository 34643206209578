





























import { Component, Mixins, Watch } from 'vue-property-decorator'

import { Inject } from '../../../../../support'
import { NavTabsItemProps } from '../../../../../dsl/molecules/NavTabs'

import CompanyMixin from '../../../../shared/mixins/company.mixin'
import { UserModel } from '../../../../auth/shared'
import { Loader } from '../../../../shared/molecules/Loader'

import { CompanyModel } from '../../../contracts/models'
import { IProfileService, ProfileServiceType } from '../../../contracts'

import BaseProfileMixin from '../Profile.mixin'
import {
  profileTabsComponentsRegistry
} from '../ProfileView.config'
import {
  ActiveContext,
  ProfileNavTabConfig, ProfileTabComponent,
  ProfileTabData,
  ProfileTabResolvedComponent
} from '../Profile.contracts'

/**
 * @author Filip Rurak <filip.rurak@movecloser.pl>
 */
@Component<TabsData>({
  name: 'TabsData',
  components: { Loader },
  async created (): Promise<void> {
    await this.fetchCompanyData()

    this.composeTabs()
    this.composeUserDataModel()
  }
})
export class TabsData extends Mixins(BaseProfileMixin, CompanyMixin) {
  @Inject(ProfileServiceType)
  protected readonly profileService!: IProfileService

  public activeTabId: string = ''
  public activeTabComponents: Array<ProfileTabResolvedComponent> | null = null
  public composedTabs: Array<ProfileTabData> | null = null

  public get disableCompanyRequestFormFields (): boolean {
    return !this.user
      ? true
      : this.user.isB2BRequested || this.user.isExistingCompanyRequested
  }

  public get tabs (): Array<NavTabsItemProps> | null {
    return this.composedTabs ? this.composedTabs.map((entry) => entry.tab) : null
  }

  public get profileNavTabsConfig () {
    return this.config.profileNavTabsConfig
  }

  public get isClaimVisible (): boolean {
    return this.activeTabId !== ActiveContext.Notification
  }

  @Watch('activeTabId')
  protected onActiveTabChange () {
    this.composeUserDataModel()
  }

  /**
   * Updates active tab and component to render
   */
  public onActiveTabUpdate (tabId: string): void {
    if (!this.composedTabs) {
      return
    }
    const updatedTab = this.composedTabs.find((element) => element.tab.id === tabId)

    if (!updatedTab) {
      return
    }

    this.activeTabId = updatedTab.tab.id
    this.activeTabComponents = updatedTab.components
  }

  /**
   * Helper method useful to override some of rendered components' inner logic or to pass additional data.
   * ... switch cases by active context as well as rendered component inside each context
   * @protected
   */
  protected composeUserDataModel (): void {
    if (!this.user || !this.activeTabComponents) {
      return
    }

    switch (this.activeTabId) {
      case ActiveContext.Account:
        this.activeTabComponents = this.activeTabComponents.map((component) => {
          const formData = TabsData.getFormDataByComponent(component.name, this.user as UserModel, this.company as CompanyModel)
          const canEdit = this.isUserB2B && this.user
            ? !this.user.isVerified ? this.user.isAdmin : false
            : !this.disableCompanyRequestFormFields

          return component.context === this.activeTabId && formData ? {
            ...component,
            props: {
              ...component.props,
              payload: formData,
              canEdit
            }
          } : {
            ...component,
            props: {
              ...component.props,
              canEdit
            }
          }
        })
        break
      case ActiveContext.Company:
        this.activeTabComponents = this.activeTabComponents.map((component) => {
          const canEdit = this.isUserB2B && this.user
            ? !this.user.isVerified ? this.user.isAdmin : false
            : !this.disableCompanyRequestFormFields

          return component.context === this.activeTabId ? {
            ...component,
            props: {
              ...component.props,
              payload: this.company,
              companyFetchFailed: this.hasCompanyFetchFailed,
              canEdit
            }
          } : {
            ...component,
            props: {
              ...component.props,
              canEdit
            }
          }
        })
        break
      case ActiveContext.Address:
        break
      case ActiveContext.Notification:
        break
      case ActiveContext.BaseSettings:
        break
    }
  }

  /**
   * Composes initial tabs
   */
  protected composeTabs (): void {
    if (!Object.prototype.hasOwnProperty.call(this.config, 'profileNavTabsConfig') || Object.entries(this.config.profileNavTabsConfig).length === 0) {
      return
    }

    const tabs = this.config.profileNavTabsConfig as ProfileNavTabConfig

    if (Object.keys(tabs).length === 0) {
      return
    }

    this.setInitialActiveTab(tabs)

    this.composedTabs = Object.entries(tabs).map(([context, component]) => {
      return {
        tab: {
          active: component.active,
          id: context,
          isDisabled: component.isDisabled,
          label: this.$t(`front.profile.views.profile.versions.tabsData.tabs.${context}`).toString(),
          order: component.order
        },
        components: component.components.map((comp) => {
          const candidateProps: any = []
          for (const [propName, propValue] of Object.entries(comp.props)) {
            let value = propValue.value
            if (propValue.useTranslations) {
              value = this.$t(`front.profile.views.profile.versions.tabsData.translationProps.${comp.name}.${propValue.value}`)
            }
            candidateProps.push([propName, value])
          }

          return {
            context: context,
            component: profileTabsComponentsRegistry[comp.name],
            name: comp.name,
            props: Object.fromEntries(candidateProps)
          }
        })
      }
    })

    if (this.composedTabs) {
      this.composedTabs = TabsData.sortTabsByOrderProp(this.composedTabs)
    }
  }

  private static getFormDataByComponent (componentName: string, user: UserModel, company: CompanyModel) {
    let defaultBillingAddress

    switch (componentName) {
      case ProfileTabComponent.ChangeAddressFormConfigurable:
        if (company) {
          defaultBillingAddress = {
            company: company.legalName,
            countryCode: company.legalAddress.countryCode,
            city: company.legalAddress.city,
            defaultBilling: true,
            defaultShipping: false,
            firstName: company.companyAdmin.firstName,
            lastName: company.companyAdmin.lastName,
            postalCode: company.legalAddress.postCode,
            phoneNumber: company.legalAddress.telephone,
            street: company.legalAddress.street.length > 1 ? company.legalAddress.street[0] + company.legalAddress.street[1] : company.legalAddress.street[0],
            region: company.legalAddress.region,
            vatId: company.vatTaxId
          }

          return defaultBillingAddress
        } else {
          defaultBillingAddress = user.addresses.find(address => address.defaultBilling)

          if (!defaultBillingAddress) {
            return undefined
          }

          if (Object.prototype.hasOwnProperty.call(defaultBillingAddress, 'countryCode')) {
            return defaultBillingAddress
          } else {
            return {
              ...defaultBillingAddress,
              countryCode: 'PL'
            }
          }
        }
    }
  }

  /**
   * Sorts tabs by order prop.
   * Sets active tab as first always
   * @param tabs
   * @private
   */
  private static sortTabsByOrderProp (tabs: Array<ProfileTabData>): Array<ProfileTabData> {
    const activeTab = tabs.filter((tab) => tab.tab.active)
    const inactiveSortedTabs = tabs.filter((tab) => !tab.tab.active).sort((a, b) => a.tab.order! < b.tab.order! ? -1 : 1)
    return activeTab.concat(inactiveSortedTabs)
  }

  /**
   * Composes active component
   * @param tabs
   * @private
   */
  private setInitialActiveTab (tabs: ProfileNavTabConfig): void {
    const activeTab = Object.entries(tabs).find(([context, component]) => component.active)

    this.activeTabId = activeTab ? activeTab[0] : ''
    this.activeTabComponents = activeTab
      ? activeTab[1].components.map((component) => {
        const candidateProps: any = []
        for (const [propName, propValue] of Object.entries(component.props)) {
          let value = propValue.value
          if (propValue.useTranslations) {
            value = this.$t(`front.profile.views.profile.versions.tabsData.translationProps.${component.name}.${propValue.value}`).toString()
          }
          candidateProps.push([propName, value])
        }

        return {
          context: activeTab[0],
          component: profileTabsComponentsRegistry[component.name],
          name: component.name,
          props: Object.fromEntries(candidateProps)
        }
      })
      : null
  }
}

export default TabsData
