




























import { Component, Mixins, Prop, PropSync } from 'vue-property-decorator'

import { FormErrorsMixin } from '../../../../support/mixins'

import { EmailResellerIdFieldsetPayload } from './EmailResellerIdFieldset.contracts'
import {
  GuestDetailsFormFieldsetProps as Data
} from '../../../checkout/molecules/GuestDetailsFormFieldset'

/**
 * @author Filip Rurak <filip.rurak@movecloser.pl>
 */
@Component({
  name: 'EmailResellerIdFieldset'
})
export class EmailResellerIdFieldset extends Mixins(FormErrorsMixin) {
  @PropSync('formData', { type: Object, required: true })
  public _formData!: EmailResellerIdFieldsetPayload

  @Prop({ type: Boolean, required: false, default: false })
  public readonly disableFormFields!: boolean

  @Prop({ type: Object, required: false, default: null })
  public errors!: Data['_errors']
}

export default EmailResellerIdFieldset
