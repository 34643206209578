




import { Component, Inject as VueInject, Mixins } from 'vue-property-decorator'
import { defaultProvider, IS_MOBILE_PROVIDER_KEY } from '../../../../support'

import { BaseCartMixin } from '../../../checkout/shared/mixins/base-cart.mixin'

import { UserMixin } from '../../shared'

import { UserRefreshMixin } from '../../../shared/mixins/userRefresh.mixin'
import { OrderStatusesMixin } from '../../../shared/mixins/orderStatuses.mixin'
import { StructureConfigurable } from '../../../../support/mixins'
import {
  ACCOUNT_VIEW_CONFIG_MAP,
  ACCOUNT_VIEW_KEY,
  AccountVariant, accountVariantsRegistry,
  AccountViewConfig
} from './Account.config'
import { AsyncComponent } from 'vue'

/**
 * @author Filip Rurak <filip.rurak@movecloser.pl>
 */
@Component<Account>({
  name: 'Account',
  created () {
    this.config = this.getComponentConfig(ACCOUNT_VIEW_KEY, { ...ACCOUNT_VIEW_CONFIG_MAP })
  },
  mounted () {
    if (this.redirectFromAccountPage && this.redirectOnDesktop && !this.isMobile()) {
      this.$router.push({ name: this.redirectFromAccountPage })
    }
  }
})
export class Account extends Mixins(UserMixin, BaseCartMixin, UserRefreshMixin, OrderStatusesMixin, StructureConfigurable) {
  @VueInject({ from: IS_MOBILE_PROVIDER_KEY, default: () => defaultProvider<boolean>(false) })
  public readonly isMobile!: () => boolean

  protected config!: AccountViewConfig

  public get component (): AsyncComponent | undefined {
    const variant = this.getConfigProperty<string>('variant') as AccountVariant

    if (!Object.values(AccountVariant).includes(variant)) {
      return
    }

    return accountVariantsRegistry[variant]
  }

  public get redirectFromAccountPage (): string {
    return this.getConfigProperty<string>('redirectFromAccountPage')
  }

  public get redirectOnDesktop (): string {
    return this.getConfigProperty<string>('redirectOnDesktop')
  }
}

export default Account
