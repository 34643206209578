






















import { Component, Inject as VueInject, Mixins, Watch } from 'vue-property-decorator'
import { VueConstructor } from 'vue'
import { EventbusType, IEventbus } from '@movecloser/front-core'

import { defaultProvider, Inject, IS_MOBILE_PROVIDER_KEY } from '../../../../support'

import { NavTabsItemProps } from '../../../../dsl/molecules/NavTabs'

import { FullscreenLoader } from '../../../shared/molecules/Loader'
import { RouteNames } from '../../../profile/routes'
import { RouteNames as AuthRouteNames } from '../../routes'
import { UserMixin } from '../../../profile/shared'

import { LoginForm } from '../../organisms/LoginForm'
import { SignupForm } from '../../organisms/SignupForm'
import {
  loginRegisterContextComponentsRegistry
} from '../../../checkout/organisms/BuyerContextSwitch/BuyerContextSwitch.config'
import { StructureConfigurable } from '../../../../support/mixins'
import { AUTH_VIEW_CONFIG_MAP, AUTH_VIEW_KEY, AuthViewConfig } from './Auth.config'

/**
 * @author Javlon Khalimjonov <javlon.khalimjonov@movecloser.pl>
 */
@Component<Auth>({
  name: 'Auth',
  components: { FullscreenLoader, LoginForm, SignupForm },
  created () {
    this.config = this.getComponentConfig(AUTH_VIEW_KEY, { ...AUTH_VIEW_CONFIG_MAP })
  }
})
export class Auth extends Mixins(UserMixin, StructureConfigurable) {
  @VueInject({ from: IS_MOBILE_PROVIDER_KEY, default: defaultProvider<boolean>(false) })
  protected readonly isMobileCallback!: () => boolean

  @Inject(EventbusType)
  protected readonly eventBus!: IEventbus

  protected config!: AuthViewConfig
  public mode: 'login' | 'register' = 'login'

  /**
   * Determines the options for tabs.
   */
  public get authModes (): NavTabsItemProps[] {
    return [
      {
        id: 'login',
        label: this.$t('front.auth.forms.logIn.mode.login').toString(),
        active: true
      },
      {
        id: 'register',
        label: this.$t('front.auth.forms.logIn.mode.register').toString(),
        active: false
      }
    ]
  }

  public get loginRegisterContext (): string {
    return this.getConfigProperty('loginRegisterContext')
  }

  public get loginRegisterContextProps (): string {
    return this.getConfigProperty('loginRegisterContextProps')
  }

  public get loginRegisterComponent (): VueConstructor | undefined {
    if (!(this.loginRegisterContext in loginRegisterContextComponentsRegistry)) {
      return
    }
    return loginRegisterContextComponentsRegistry[this.loginRegisterContext]
  }

  /**
   * Determines the form to be rendered based on selected choice.
   */
  public get component (): VueConstructor {
    return this.mode === 'login' ? LoginForm : SignupForm
  }

  /**
   * Determines whether current device is mobile.
   */
  public get isMobile (): boolean {
    return this.isMobileCallback()
  }

  public onForgotPassword (): void {
    this.$router.push({
      name: `auth.${AuthRouteNames.RequestResetPassword}`
    })
  }

  @Watch('user')
  protected onUserChange (): void {
    if (!this.user) {
      return
    }

    const redirectTo = this.$store.getters['auth/redirectTo']
    this.$store.commit('auth/SET_REDIRECT_TO', null)
    this.eventBus.emit('app:authorization.login', this.user)
    this.$router.push(redirectTo ?? { name: `profile.${RouteNames.Profile}` })
  }
}
export default Auth
