
import { VueConstructor } from 'vue'

import CreateCompanyFieldset from '../../../auth/organisms/CreateCompanyFieldset/CreateCompanyFieldset.vue'

import { EmailResellerIdFieldset } from '../../molecules/EmailResellerIdFieldset'

import { RequestCompanyFormConfig, RequestCompanyMode } from './RequestCompanyForm.contracts'

/**
 * @author Filip Rurak <filip.rurak@movecloser.pl>
 */
export const REQUEST_COMPANY_FORM_KEY = 'RequestCompanyFormComponent'

/**
 * @author Filip Rurak <filip.rurak@movecloser.pl>
 */
export const REQUEST_COMPANY_FORM_CONFIG_MAP: RequestCompanyFormConfig = {
  validatorsMap: null
}

/**
 * @author Filip Rurak <filip.rurak@movecloser.pl>
 */
export const requestCompanyFormComponents: Record<string, VueConstructor> = {
  [RequestCompanyMode.ExistingCompany]: EmailResellerIdFieldset,
  [RequestCompanyMode.NewCompany]: CreateCompanyFieldset
}
