








































import { Component, Mixins, Prop } from 'vue-property-decorator'
import {
  AnyObject,
  Authentication,
  AuthServiceType,
  EventbusType,
  IEventbus
} from '@movecloser/front-core'

import { FormErrorsMixin, StructureConfigurable } from '../../../../support/mixins'
import { Inject, logger } from '../../../../support'
import { ISiteService, SiteServiceType } from '../../../../contexts'

import { AuthMixin, UserModel } from '../../../auth/shared'
import { ToastType } from '../../../shared/services'

import Consents from '../../../shared/molecules/Consents/Consents.vue'
import { Form } from '../../../shared/molecules/Form'
import { Loader } from '../../../shared/molecules/Loader'

import { IProfileService, ProfileServiceType } from '../../contracts'
import { UserMixin } from '../../shared'

import {
  CHANGE_SUBSCRIPTIONS_FORM_CONFIG,
  CHANGE_SUBSCRIPTIONS_FORM_KEY
} from './ChangeSubscriptionsPreferencesForm.config'
import {
  ChangeSubscriptionConsentProps,
  ChangeSubscriptionsPreferencesFormConfig, SubscriptionsFormDataEntitySetup
} from './ChangeSubscriptionsPreferencesForm.contracts'
import { ConsentProps } from '../../../shared/molecules/Consents/Consents.contracts'

/**
 * @author Filip Rurak <filip.rurak@movecloser.pl>
 */
@Component<ChangeSubscriptionsPreferencesForm>({
  name: 'ChangeSubscriptionsPreferencesForm',
  components: { Consents, Form, Loader },
  async created () {
    this.config = this.getComponentConfig(CHANGE_SUBSCRIPTIONS_FORM_KEY, { ...CHANGE_SUBSCRIPTIONS_FORM_CONFIG })
    await this.describeConsents()
  }
})
export class ChangeSubscriptionsPreferencesForm extends Mixins(AuthMixin, FormErrorsMixin, UserMixin, StructureConfigurable) {
  @Inject(AuthServiceType, false)
  private readonly authService?: Authentication<UserModel>

  @Inject(EventbusType)
  protected readonly eventBus!: IEventbus

  @Inject(ProfileServiceType)
  protected readonly profileService!: IProfileService

  @Inject(SiteServiceType)
  public readonly siteService!: ISiteService

  @Prop({ type: Array, required: false, default: () => [] })
  public consents!: ChangeSubscriptionConsentProps[]

  @Prop({ type: Object, required: false, default: null })
  public readonly consentsDescription!: Record<string, string> | null

  @Prop({ type: String, required: false })
  public headingProp?: string

  @Prop({ type: String, required: false })
  public providerProp?: string

  @Prop({ type: Object, required: false })
  public formDataProp?: Record<string, SubscriptionsFormDataEntitySetup>

  @Prop({ type: String, required: false })
  public submitCtaLabel?: string

  @Prop({ type: String, required: false })
  public cancelCtaLabel?: string

  @Prop({ type: Boolean, required: false })
  public renderClaim?: boolean

  public describedConsents: ConsentProps[] = []
  /**
   * Validators map.
   */
  public readonly validatorsMap = {}

  /**
   * Forms payload.
   */
  public formData: Record<string, unknown> = {}
  public isLoading: boolean = true
  protected config!: ChangeSubscriptionsPreferencesFormConfig

  public get provider (): string {
    return this.providerProp ?? this.getConfigProperty('provider')
  }

  public get privacyPolicyLocation (): string | null {
    const privacyPolicyLocation = this.siteService.getActiveSite().properties.privacyPolicyLocation
    return privacyPolicyLocation ? privacyPolicyLocation.toString() : null
  }

  public get termsLocation (): string | null {
    const regulationsLocation = this.siteService.getActiveSite().properties.regulationsLocation
    return regulationsLocation ? regulationsLocation.toString() : null
  }

  public get shouldRenderClaim (): boolean {
    return !!this.renderClaim && !!this.termsLocation && !!this.privacyPolicyLocation
  }

  /**
   * Handles @fail event of form.
   */
  public onFail (error: Error): void {
    // TODO: Update formData key when error occurs
    this.setError(error)
  }

  /**
   * Submits the form.
   */
  public async onSubmit (): Promise<void> {
    this.error = ''

    this.eventBus.emit('app:newsletter.consents', {
      ...this.formData,
      email: this.user?.email,
      firstName: this.user?.firstName ?? undefined,
      lastName: this.user?.lastName ?? undefined,
    })
  }

  public onSuccess (): void {
    this.showToast(
      this.$t('front._common.saved').toString(),
      ToastType.Success
    )
  }

  /**
   * Describe consents
   * @protected
   */
  private async describeConsents (): Promise<void> {
    if (!this.consentsDescription) {
      return
    }

    let savedConsents: Record<string, boolean> = {}

    try {
      const response = await this.profileService.getUserSubscriptions()
      savedConsents = response.agreements
    } catch (e) {
      logger(e, 'warn')
    }

    for (const consent of this.consents) {
      const candidate = consent.option
      const consentKey = this.consentsDescription[candidate]

      if (consentKey) {
        this.formData = {
          ...this.formData,
          [consentKey]: savedConsents[consentKey] ?? consent.value ?? false
        }

        this.describedConsents.push({
          ...consent,
          option: consentKey
        })
      }
    }
  }
}

export default ChangeSubscriptionsPreferencesForm
