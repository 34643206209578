


























import { Component } from 'vue-property-decorator'

import { Inject } from '../../../../support'
import { ISiteService, SiteServiceType } from '../../../../contexts'
import { StructureConfigurable } from '../../../../support/mixins'
import {
  SIGNUP_WELCOME_VIEW_CONFIG_MAP,
  SIGNUP_WELCOME_VIEW_KEY,
  SignUpWelcomeConfig
} from './SignUpWelcome.config'

/**
 * @author Filip Rurak <filip.rurak@movecloser.pl>
 */
@Component<SignUpWelcome>({
  name: 'SignUpWelcome',
  created () {
    this.config = this.getComponentConfig(SIGNUP_WELCOME_VIEW_KEY, { ...SIGNUP_WELCOME_VIEW_CONFIG_MAP })
  }
})
export class SignUpWelcome extends StructureConfigurable {
  @Inject(SiteServiceType)
  public readonly siteService!: ISiteService

  protected config!: SignUpWelcomeConfig

  public get buttonVariant (): string {
    return this.getConfigProperty('buttonVariant')
  }

  public get siteData () {
    return {
      logo: this.siteService.getActiveSite().logo.light,
      name: this.siteService.getActiveSite().name
    }
  }
}

export default SignUpWelcome
