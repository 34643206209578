





























import { Component, Mixins, Prop, Watch } from 'vue-property-decorator'
import { AnyObject } from '@movecloser/front-core'

import { FormErrorsMixin } from '../../../../support/mixins'
import {
  INipValidationService,
  NipValidationServiceType
} from '../../../shared/services/nip-validation'
import { Inject, logger } from '../../../../support'
import { ISelenaFormService, SelenaFormServiceType } from '../../../shared/services/selenaForms'
import { SelenaFormModuleVersion } from '../../../../modules/SelenaForm/SelenaForm.config'

import CreateCompanyFieldset
  from '../../../auth/organisms/CreateCompanyFieldset/CreateCompanyFieldset.vue'
import { Form, ValidationRules } from '../../../shared/molecules/Form'

import { CompanyModel } from '../../contracts/models'
import { CompanyRepositoryType, ICompanyRepository } from '../../repositories/company'
import { IProfileService, ProfileServiceType } from '../../contracts'
import { UserMixin } from '../../shared'

import {
  changeCompanyAdministratorDataValidationMap,
  changeCompanyDataValidatorsMap
} from './ChangeCompanyDataForm.config'

import RequestCompanyForm from '../RequestCompanyForm/RequestCompanyForm.vue'
import {
  ExistingCompanyRequestPayload,
  NewCompanyRequestPayload,
  RequestCompanyMode
} from '../RequestCompanyForm/RequestCompanyForm.contracts'
import CompanyMixin from '../../../shared/mixins/company.mixin'
import { AuthMixin } from '../../../auth/shared'

/**
 * @author Filip Rurak <filip.rurak@movecloser.pl>
 */
@Component<ChangeCompanyDataForm>({
  name: 'ChangeCompanyDataForm',
  components: { CreateCompanyFieldset, Form, RequestCompanyForm },
  created (): void {
    this.composeFormData()
    // TODO: Fetch user notifications
  },
  mounted () {
    this.setUserConversionType()
  }
})
export class ChangeCompanyDataForm extends Mixins(CompanyMixin, AuthMixin, UserMixin, FormErrorsMixin) {
  @Inject(CompanyRepositoryType)
  protected readonly companyRepository!: ICompanyRepository

  @Inject(NipValidationServiceType)
  protected readonly nipValidationService!: INipValidationService

  @Inject(ProfileServiceType)
  protected readonly profileRepository!: IProfileService

  @Inject(SelenaFormServiceType)
  protected readonly selenaFormsService!: ISelenaFormService

  @Prop({ type: Boolean, required: false, default: false })
  public readonly canEdit!: boolean

  @Prop({ type: Boolean, required: false, default: false })
  public readonly companyFetchFailed!: boolean

  @Prop({ type: Array, required: false, default: [] })
  public readonly consents!: Array<AnyObject>

  @Prop({ type: Object, required: false, default: null })
  public readonly consentsDescription!: Record<string, string> | null

  @Prop({ type: Object, required: false, default: null })
  public readonly payload!: CompanyModel | null

  public companyFormError: string = ''
  public companyFormSent: boolean = false
  public companyRequestMode: string = RequestCompanyMode.NewCompany
  public formData: ExistingCompanyRequestPayload | NewCompanyRequestPayload | null = null
  public formHasError: boolean = false
  public isLoading: boolean = false

  @Watch('companyRequestMode')
  private onCompanyRequestModeChange (): void {
    this.composeFormData()
  }

  public get showClaim (): boolean {
    return this.company && this.user
      ? this.user.isVerified
        ? true
        : !this.user.isAdmin
      : true
  }

  public get formClaim (): string {
    return this.company
      ? this.$t('front.profile.organisms.changeCompanyDataForm.editDescription').toString()
      : this.isRequestFormSent
        ? this.$t('front.profile.organisms.changeCompanyDataForm.claim.formSent').toString()
        : this.$t(`front.profile.organisms.changeCompanyDataForm.claim.${this.companyRequestMode}`).toString()
  }

  public get isRequestFormSent (): boolean {
    return !this.user
      ? true
      : this.user.isB2BRequested || this.user.isExistingCompanyRequested
  }

  public get validatorsMap (): ValidationRules {
    return this.canEdit ? changeCompanyDataValidatorsMap : changeCompanyAdministratorDataValidationMap
  }

  public onFail (error: Error): void {
    this.companyFormError = error.message

    this.isLoading = false
  }

  public onSuccess (): void {
    this.companyFormError = ''

    this.showToast(this.$t('front.profile.organisms.changeCompanyDataForm.success').toString(), 'success')
    this.isLoading = false
  }

  /**
   * Composes formData based on currently displayed form
   * @protected
   */
  private composeFormData (): void {
    if (!this.user) {
      return
    }

    switch (this.companyRequestMode) {
      case RequestCompanyMode.NewCompany:
        this.formData = {
          adminEmail: this.user.email,
          adminFirstName: this.user.firstName ?? '',
          adminLastName: this.user.lastName ?? '',
          city: '',
          companyEmail: this.user.email,
          countryCode: '',
          legalName: '',
          companyName: '',
          postCode: '',
          region: null,
          vatTaxId: '',
          street: '',
          streetSecondLine: '',
          telephone: ''
        }
        break
      case RequestCompanyMode.ExistingCompany:
        this.formData = {
          email: this.user.email,
          vatTaxId: ''
        }
    }

    this.propagateFormDataWithPayload()
  }

  /**
   * Describe consents
   * @protected
   */
  private describeConsents (): void {
    for (const consent of Object.values(this.consents)) {
      const foundDescription = this.consentsDescription![consent.option]
      if (foundDescription) {
        this.formData = {
          ...this.formData!,
          [foundDescription]: false
        }
      }
    }
  }

  /**
   * Propagates formData with component's payload
   * @private
   */
  private propagateFormDataWithPayload (): void {
    if (!this.payload) {
      return
    }

    this.formData = {
      adminEmail: this.payload.companyAdmin.email,
      adminFirstName: this.payload.companyAdmin.firstName,
      adminLastName: this.payload.companyAdmin.lastName,
      city: this.payload.legalAddress.city,
      companyEmail: this.payload.email,
      countryCode: this.payload.legalAddress.countryCode,
      legalName: this.payload.legalName,
      companyName: this.payload.name,
      postCode: this.payload.legalAddress.postCode,
      region: this.payload.legalAddress.region,
      vatTaxId: this.payload.vatTaxId,
      street: this.payload.legalAddress.street[0],
      streetSecondLine: this.payload.legalAddress.street.length > 1 ? this.payload.legalAddress.street[1] : '',
      telephone: this.payload.legalAddress.telephone
    }

    if (this.consents && this.consentsDescription) {
      this.describeConsents()
    }
  }

  /**
   * Submits company data update
   */
  public async submit (): Promise<void> {
    if (!this.formData) {
      return
    }

    this.isLoading = true

    await this.profileRepository.changeUserDetails({
      firstName: (this.formData as NewCompanyRequestPayload).adminFirstName,
      lastName: (this.formData as NewCompanyRequestPayload).adminLastName,
    })

    if (!this.canEdit) {
      return
    }

    await this.companyRepository.updateCompany({
      companyEmail: (this.formData as NewCompanyRequestPayload).adminEmail,
      legalAddress: {
        street: [(this.formData as NewCompanyRequestPayload).street, (this.formData as NewCompanyRequestPayload).streetSecondLine],
        city: (this.formData as NewCompanyRequestPayload).city,
        region: (this.formData as NewCompanyRequestPayload).region!,
        postCode: (this.formData as NewCompanyRequestPayload).postCode,
        countryCode: (this.formData as NewCompanyRequestPayload).countryCode,
        telephone: (this.formData as NewCompanyRequestPayload).telephone
      },
      legalName: (this.formData as NewCompanyRequestPayload).legalName,
      companyName: (this.formData as NewCompanyRequestPayload).companyName,
      vatTaxId: (this.formData as NewCompanyRequestPayload).vatTaxId
    })
  }

  /**
   * Sends request
   * @param payload
   */
  public async onRequestCompany (payload: ExistingCompanyRequestPayload | NewCompanyRequestPayload): Promise<void> {
    let validationResult
    switch (this.companyRequestMode) {
      case RequestCompanyMode.NewCompany:
        await this.sendSelenaForm(SelenaFormModuleVersion.ConvertUserToB2B, {
          adminEmail: (payload as NewCompanyRequestPayload).adminEmail,
          adminFirstName: (payload as NewCompanyRequestPayload).adminFirstName,
          adminLastName: (payload as NewCompanyRequestPayload).adminLastName,
          companyEmail: (payload as NewCompanyRequestPayload).companyEmail,
          companyStreet: [(payload as NewCompanyRequestPayload).street, (payload as NewCompanyRequestPayload).streetSecondLine],
          companyCity: (payload as NewCompanyRequestPayload).city,
          companyRegion: (payload as NewCompanyRequestPayload).region?.name,
          companyPostCode: (payload as NewCompanyRequestPayload).postCode,
          companyCountryCode: (payload as NewCompanyRequestPayload).countryCode,
          companyTelephone: (payload as NewCompanyRequestPayload).telephone,
          companyLegalName: (payload as NewCompanyRequestPayload).legalName,
          companyName: (payload as NewCompanyRequestPayload).companyName,
          companyVatTaxId: (payload as NewCompanyRequestPayload).vatTaxId,
        })

        if (this.formHasError) {
          return
        }
        break
      case RequestCompanyMode.ExistingCompany:
        validationResult = await this.validateCompanyResellerId((payload as ExistingCompanyRequestPayload).vatTaxId)

        if (validationResult.errorMessage) {
          this.showToast(validationResult.errorMessage, 'danger')
          return
        }

        await this.sendSelenaForm(SelenaFormModuleVersion.ConnectUser, { ...payload })

        if (this.formHasError) {
          return
        }
    }

    this.companyFormSent = true
    await this.saveRequestDetails(this.companyRequestMode)
    this.setUserConversionType()
  }

  protected async saveRequestDetails (type: string): Promise<void> {
    if (!this.user) {
      return
    }

    this.isLoading = true

    try {
      let requestType: string = ''
      switch (type) {
        case RequestCompanyMode.NewCompany:
          requestType = 'isB2BRequested'
          break
        case RequestCompanyMode.ExistingCompany:
          requestType = 'isExistingCompanyRequested'
          break
      }

      if (!requestType) {
        this.isLoading = false
        return
      }

      await this.profileRepository.changeUserDetails({
        [requestType]: true,
        firstName: this.user.firstName ?? '',
        lastName: this.user.lastName ?? ''
      })
    } catch (e) {
      logger(e, 'warn')
    } finally {
      this.isLoading = false
    }
  }

  /**
   * Sends selena form
   * @param formName
   * @param payload
   * @protected
   */
  protected async sendSelenaForm (formName: string, payload: AnyObject): Promise<void> {
    this.formHasError = false

    try {
      await this.selenaFormsService.send(formName, payload)
      this.showToast(this.$t('front.profile.organisms.requestCompanyForm.formSent').toString(), 'success')
    } catch (e) {
      logger(e, 'warn')
      this.showToast((e as Error).message, 'danger')
      this.formHasError = true
    }
  }

  /**
   * Validates whether provided resellerId is a valid tax number
   * @param resellerId
   * @protected
   */
  protected async validateCompanyResellerId (resellerId: string): Promise<{ errorMessage: string | null }> {
    try {
      await this.nipValidationService.validateCompanyVatId(resellerId)
      return { errorMessage: null }
    } catch (e) {
      logger(e, 'warn')
      return { errorMessage: (e as Error).message }
    }
  }

  private setUserConversionType (): void {
    if (!this.user) {
      return
    }

    if (this.user.isB2BRequested && !this.user.isExistingCompanyRequested) {
      this.companyRequestMode = RequestCompanyMode.NewCompany
    } else if (this.user.isExistingCompanyRequested && !this.user.isB2BRequested) {
      this.companyRequestMode = RequestCompanyMode.ExistingCompany
    }
  }
}

export default ChangeCompanyDataForm
