
























































import { Component, Mixins, Prop, Inject as VueInject } from 'vue-property-decorator'
import { Authentication, AuthServiceType } from '@movecloser/front-core'

import {
  AddressData,
  AddressType,
  Country,
  ISiteService,
  SiteServiceType
} from '../../../../contexts'
import { defaultProvider, Inject, IS_MOBILE_PROVIDER_KEY } from '../../../../support'
import { FormErrorsMixin, StructureConfigurable } from '../../../../support/mixins'

import { AuthMixin, UserModel } from '../../../auth/shared'
import {
  DictionaryServiceType,
  IDictionaryService
} from '../../../shared/services/dictionary'
import { Loader } from '../../../shared/molecules/Loader'
import { SimpleForm } from '../../../shared/molecules/Form'
import { ToastMixin } from '../../../shared'
import { ToastType } from '../../../shared/services'

import { ChangeAddressFormFields } from '../../molecules/ChangeAddressFormFields'
import { IProfileService, ProfileServiceType } from '../../contracts'
import { UserMixin } from '../../shared'

import {
  translateCountryToCountrySelectOption
} from './ChangeAddressForm.helpers'
import {
  CHANGE_ADDRESS_FORM_COMPONENT_CONFIG_MAP,
  CHANGE_ADDRESS_FORM_COMPONENT_KEY,
  ChangeAddressFormConfig, FormAction
} from './ChangeAddressForm.config'
import { DeliveryAddressFormData } from './ChangeAddressForm.contracts'

/**
 * @author Javlon Khalimjonov <javlon.khalimjonov@movecloser.pl> (original)
 * @author Wojciech Falkowski <wojciech.falkowski@movecloser.pl> (edited)
 * @author Filip Rurak <filip.rurak@movecloser.pl> (edited)
 */
@Component<ChangeAddressFormConfigurable>({
  name: 'ChangeAddressFormConfigurable',
  components: { ChangeAddressFormFields, Loader, SimpleForm },
  created (): void {
    this.config = this.getComponentConfig(CHANGE_ADDRESS_FORM_COMPONENT_KEY, { ...CHANGE_ADDRESS_FORM_COMPONENT_CONFIG_MAP })
    /**
     * @inheritDoc
     */
    this.composeFormData()
  }
})
export class ChangeAddressFormConfigurable extends Mixins(AuthMixin, FormErrorsMixin, StructureConfigurable, UserMixin, ToastMixin, ToastMixin) {
  @VueInject({ from: IS_MOBILE_PROVIDER_KEY, default: () => defaultProvider<boolean>(false) })
  public readonly isMobile!: () => boolean

  @Prop({ type: Boolean, required: true })
  public defaultAddress!: boolean

  @Prop({ type: Object, required: false })
  public payload?: DeliveryAddressFormData

  @Prop({ type: String, required: false })
  public addressType?: string

  @Prop({ type: Boolean, required: false, default: true })
  public displayFormHeading!: boolean

  @Prop({ type: Boolean, required: false, default: false })
  public isAlwaysCompany?: boolean

  @Prop({ type: Boolean, required: false, default: false })
  public canEdit!: boolean

  @Prop({ type: String, required: false, default: null })
  public formHeadingProp?: string

  @Prop({ type: String, required: false, default: null })
  public formEditLabelProp?: string

  @Prop({ type: Boolean, required: false, default: false })
  public hasRegionField?: boolean

  @Prop({ type: Boolean, required: false, default: true })
  public simpleFormValidate!: boolean

  @Prop({ type: Boolean, required: false, default: false })
  public shouldAddNew!: boolean

  @Prop({ type: Boolean, required: false, default: true })
  public showButtons!: boolean

  @Prop({ type: Array, required: false, default: [] })
  public fieldsFixedDisabled!: Array<string>

  @Prop({ type: Boolean, required: false, default: true })
  public showCompanyFields!: boolean

  @Inject(AuthServiceType)
  protected readonly authService!: Authentication<UserModel>

  @Inject(DictionaryServiceType)
  protected readonly dictionaryService!: IDictionaryService

  @Inject(ProfileServiceType)
  protected readonly profileService!: IProfileService

  @Inject(SiteServiceType)
  protected readonly siteService!: ISiteService

  protected config!: ChangeAddressFormConfig

  /** Defines action that should be handled on form */
  public formAction: string = FormAction.Update

  public formData: DeliveryAddressFormData = {
    company: '',
    countryCode: 'PL',
    city: '',
    defaultBilling: false,
    defaultShipping: false,
    firstName: '',
    lastName: '',
    postalCode: '',
    phoneNumber: '',
    street: ''
  }

  public isCompany: boolean = false

  public isLoading: boolean = true

  public get validatorsMap () {
    return this.getConfigProperty('validators')
  }

  public get countries (): Array<Country> {
    return translateCountryToCountrySelectOption(this.dictionaryService.countries)
  }

  public get simpleFormHeading (): string {
    if (this.formHeadingProp) {
      return this.formHeadingProp.toString()
    }

    return this.$t('front.profile.organisms.savedAddressesForm.header').toString()
  }

  public get simpleFormEditLabel (): string {
    if (this.formEditLabelProp) {
      return this.formEditLabelProp.toString()
    }

    return this.$t('front.profile.organisms.savedAddressesForm.add').toString()
  }

  public get shouldBeFluid (): boolean {
    return this.getConfigProperty<boolean>('shouldBeFluid') && this.isMobile()
  }

  public get formHeading (): string {
    if (this.formHeadingProp) {
      return this.formHeadingProp
    }

    switch (this.addressType) {
      case AddressType.Shipping:
        switch (this.defaultAddress) {
          case true:
            return String(this.$t('front.profile.views.addressesBillings.default.shipping.formHeading'))
          case false:
            return String(this.$t('front.profile.views.addressesBillings.additional.formHeading'))
        }
        break
      case AddressType.Billing:
        switch (this.defaultAddress) {
          case true:
            return String(this.$t('front.profile.views.addressesBillings.default.billing.formHeading'))
          case false:
            return String(this.$t('front.profile.views.addressesBillings.additional.formHeading'))
        }
    }
    return String(this.$t('front.profile.views.addressesBillings.additional.formHeading'))
  }

  public onIsCompanyUpdate (value: boolean): void {
    this.isCompany = value

    /** Update formData with new key */
    this.formData = {
      ...this.formData,
      vatId: ''
    }
  }

  /**
   * Adds delivery address.
   */
  public addDeliveryAddress (): Promise<number> {
    this.isSubmitted = null
    this.error = ''

    if (this.formAction === FormAction.Update && typeof this.payload !== 'undefined') {
      return this.profileService.updateDeliveryAddress(this.payload.id as number, this.formData)
    }

    return this.profileService.storeDeliveryAddress(this.formData)
  }

  public onSuccess (addressId: number): void {
    this.$emit('onUpdate', { id: addressId, data: this.formData })

    if (this.formAction === FormAction.Add) {
      this.showToast(String(this.$t('front.profile.views.addressesBillings.info.add.success')), ToastType.Success)
    } else {
      this.showToast(String(this.$t('front.profile.views.addressesBillings.info.edit.success')), ToastType.Success)
    }

    this.$emit('onSuccess')
  }

  public onFail (e: Error): void {
    this.setError(e)

    if (this.formAction === FormAction.Add) {
      this.showToast(String(this.$t('front.profile.views.addressesBillings.info.add.warning')), ToastType.Warning)
    } else {
      this.showToast(String(this.$t('front.profile.views.addressesBillings.info.edit.warning')), ToastType.Warning)
    }

    this.$emit('onFail')
  }

  /**
   * Init component formData from payload
   * @protected
   */
  protected composeFormData (): void {
    /** Define action that should be triggered on form */
    if (!this.payload) {
      this.formAction = FormAction.Add
    } else {
      this.formAction = FormAction.Update
    }

    /** Set default values and addresses type */
    if (this.addressType === AddressType.Billing) {
      if (this.defaultAddress) {
        this.formData = {
          ...this.formData,
          defaultBilling: true,
          defaultShipping: false
        }
      } else {
        this.formData = {
          ...this.formData,
          defaultBilling: false,
          defaultShipping: false
        }
      }
    } else {
      if (this.defaultAddress) {
        this.formData = {
          ...this.formData,
          defaultShipping: true,
          defaultBilling: false
        }
      } else {
        this.formData = {
          ...this.formData,
          defaultShipping: false,
          defaultBilling: false
        }
      }
    }

    if (!this.payload) {
      if (this.hasRegionField) {
        this.formData = {
          ...this.formData,
          region: ''
        }
      }
      this.isLoading = false
      return
    }

    /** Set other properties if exits */
    for (const [key, value] of Object.entries(this.payload)) {
      this.formData = {
        ...this.formData,
        [key as keyof AddressData]: value
      }
    }

    /** Enable isCompany */
    if (this.payload.vatId || this.payload.company) {
      this.isCompany = true
    }

    this.isLoading = false
  }
}

export default ChangeAddressFormConfigurable
