




















import { Component, Mixins, Prop, PropSync } from 'vue-property-decorator'
import { VueConstructor } from 'vue'

import { FormErrorsMixin, StructureConfigurable } from '../../../../support/mixins'

import { Form, ValidationRules } from '../../../shared/molecules/Form'
import {
  FormContextSwitchContracts
} from '../../../checkout/molecules/FormContextSwitch/FormContextSwitch.contracts'
import RadioSwitch from '../../../checkout/molecules/FormContextSwitch/RadioSwitch.vue'

import {
  ExistingCompanyRequestPayload, NewCompanyRequestPayload,
  RequestCompanyFormConfig,
  RequestCompanyMode
} from './RequestCompanyForm.contracts'
import {
  REQUEST_COMPANY_FORM_CONFIG_MAP,
  REQUEST_COMPANY_FORM_KEY,
  requestCompanyFormComponents
} from './RequestCompanyForm.config'
import { CompanyModel } from '../../contracts/models'
import { UserModel } from '../../../auth/shared'

/**
 * @author Filip Rurak <filip.rurak@movecloser.pl>
 */
@Component<RequestCompanyForm>({
  name: 'RequestCompanyForm',
  components: { Form, RadioSwitch },
  created (): void {
    this.config = this.getComponentConfig(REQUEST_COMPANY_FORM_KEY, { ...REQUEST_COMPANY_FORM_CONFIG_MAP })
  }
})
export class RequestCompanyForm extends Mixins(FormErrorsMixin, StructureConfigurable) {
  @PropSync('requestMode', { type: String, required: true })
  public _mode!: string

  @PropSync('formData', { type: Object, required: true })
  public _formData!: ExistingCompanyRequestPayload | NewCompanyRequestPayload

  @Prop({ type: Boolean, required: false, default: false })
  public readonly canEdit!: boolean

  @Prop({ type: Object, required: false, default: null })
  public readonly company!: CompanyModel | null

  @Prop({ type: Object, required: false, default: null })
  public readonly user!: UserModel | null

  @Prop({ type: Boolean, required: false, default: false })
  public readonly companyFormSent!: boolean

  @Prop({ type: Function, required: true })
  public submitCallback!: <T>(payload: T) => Promise<void>

  protected config!: RequestCompanyFormConfig
  public isLoading: boolean = false

  public get formComponent (): VueConstructor {
    return requestCompanyFormComponents[this.company ? RequestCompanyMode.NewCompany : this._mode]
  }

  public get contexts (): FormContextSwitchContracts {
    return {
      radio: [
        {
          name: 'requestMode',
          label: RequestCompanyMode.NewCompany,
          value: RequestCompanyMode.NewCompany,
          id: RequestCompanyMode.NewCompany
        },
        {
          name: 'requestMode',
          label: RequestCompanyMode.ExistingCompany,
          value: RequestCompanyMode.ExistingCompany,
          id: RequestCompanyMode.ExistingCompany
        }
      ]
    }
  }

  public get validatorsMap (): ValidationRules | null {
    const map = this.getConfigProperty('validatorsMap')
    return map[this._mode] ?? null
  }

  public get shouldRender (): boolean {
    return !!this.validatorsMap && !!this._formData
  }

  public isFormSent (mode: string) {
    switch (mode) {
      case RequestCompanyMode.NewCompany:
        return this.user?.isB2BRequested
      case RequestCompanyMode.ExistingCompany:
        return this.user?.isExistingCompanyRequested
    }
  }

  public get disableContextSwitcher (): boolean {
    return !this.user
      ? true
      : this.company
        ? true
        : this.user.isB2BRequested || this.user.isExistingCompanyRequested
  }

  public onFail (error: Error): void {
    this.showToast(error.message, 'danger')
  }

  public onSuccess (): void {
    // TODO
  }

  public async onRequestCompany (): Promise<void> {
    this.errors = null
    this.isLoading = true

    switch (this._mode) {
      case RequestCompanyMode.NewCompany:
        await this.submitCallback<NewCompanyRequestPayload>(this._formData as NewCompanyRequestPayload)
        break
      case RequestCompanyMode.ExistingCompany:
        await this.submitCallback<ExistingCompanyRequestPayload>(this._formData as ExistingCompanyRequestPayload)
        break
    }

    this.isLoading = false
  }
}

export default RequestCompanyForm
