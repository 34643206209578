import { VueConstructor } from 'vue'

import { AnyObject } from '@movecloser/front-core'

import { NavTabsItemProps } from '../../../../dsl/molecules/NavTabs'

/**
 * @author Filip Rurak <filip.rurak@movecloser.pl>
 */
export interface ProfileTabData {
  tab: NavTabsItemProps
  components: Array<ProfileTabResolvedComponent>
}

/**
 * @author Filip Rurak <filip.rurak@movecloser.pl>
 */
export interface ProfileTabResolvedComponent {
  component: VueConstructor
  context: string
  formData?: AnyObject
  name: string
  props: AnyObject
}

/**
 * @author Filip Rurak <filip.rurak@movecloser.pl>
 */
export enum ActiveContext {
  Account = 'account',
  Address = 'address',
  Company = 'company',
  BaseSettings = 'baseSettings',
  Notification = 'notification'
}

/**
 * @author Flip Rurak <filip.rurak@movecloser.pl>
 */
export type ProfileNavTabConfig = Record<string, ProfileNavTabEntity>

/**
 * @author Flip Rurak <filip.rurak@movecloser.pl>
 */
export interface ProfileTabComponentConfig {
  name: string
  props: Record<string, {
    value: AnyObject | string | number | boolean
    useTranslations: false
  }>
}

/**
 * @author Flip Rurak <filip.rurak@movecloser.pl>
 */
export interface ProfileNavTabEntity {
  active: boolean
  components: Array<ProfileTabComponentConfig>
  isDisabled: boolean
  order: number
}

/**
 * @author Flip Rurak <filip.rurak@movecloser.pl>
 */
export enum ProfileTabComponent {
  ChangeAddressFormConfigurable = 'ChangeAddressFormConfigurable',
  ChangeCompanyDataForm = 'ChangeCompanyDataForm',
  ChangeEmailForm = 'ChangeEmailForm',
  ChangePasswordForm = 'ChangePasswordForm',
  ChangeAddressForm = 'ChangeAddressForm',
  ChangeSubscriptionsPreferencesForm = 'ChangeSubscriptionsPreferencesForm'
}
