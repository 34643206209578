
/**
 * @author Filip Rurak <filip.rurak@movecloser.pl>
 */
export const SIGNUP_WELCOME_VIEW_KEY = 'SignUpWelcome'

/**
 * @author Filip Rurak <filip.rurak@movecloser.pl>
 */
export interface SignUpWelcomeConfig {
  buttonVariant: string
}

/**
 * @author Filip Rurak <filip.rurak@movecloser.pl>
 */
export const SIGNUP_WELCOME_VIEW_CONFIG_MAP: SignUpWelcomeConfig = {
  buttonVariant: 'full'
}
