










































































import { AnyObject } from '@movecloser/front-core'
import { Component, Mixins } from 'vue-property-decorator'
import { RawLocation } from 'vue-router'

import { ISiteService, SiteServiceType } from '../../../../contexts'

import { FormErrorsMixin, StructureConfigurable } from '../../../../support/mixins'
import { Inject } from '../../../../support'

import { Form } from '../../../shared/molecules/Form'
import { DrawerType, IDrawer } from '../../../shared/contracts/services'

import { AuthControlServiceType, IAuthControl } from '../../contracts'
import { openAuthDrawer } from '../../shared'
import { RouteNames } from '../../routes'
import { RequestResetLinkFormData, resetPasswordValidationsMap } from '../../views/contracts'
import {
  defaultComponentConfig,
  RESET_PASSWORD_FORM_COMPONENT_KEY
} from './ResetPasswordForm.config'

/**
 * @author Javlon Khalimjonov <javlon.khalimjonov@movecloser.pl>
 */
@Component<ResetPasswordForm>({
  name: 'ResetPasswordForm',
  components: {
    Form
  },
  created (): void {
    this.config = this.getComponentConfig(RESET_PASSWORD_FORM_COMPONENT_KEY, defaultComponentConfig)

    this.brandImage = this.siteService.getActiveSite().logo.light
  }
})
export class ResetPasswordForm extends Mixins<StructureConfigurable,
  FormErrorsMixin>(StructureConfigurable, FormErrorsMixin) {
  @Inject(AuthControlServiceType)
  protected readonly authControl!: IAuthControl

  @Inject(DrawerType, false)
  private readonly drawerService?: IDrawer

  @Inject(SiteServiceType)
  protected readonly siteService!: ISiteService

  public brandImage: string = ''

  public formData: RequestResetLinkFormData = {
    email: '',
    password: '',
    newPassword: ''
  }

  public REDIRECT_PERIOD: number = 2000

  public isSuccessful: boolean = false
  public showBanner: boolean = false

  /**
   * Validators map.
   */
  public validatorsMap = resetPasswordValidationsMap

  /**
   * Determines the possible redirect link after successful action.
   */
  public get goToOnSuccessLink (): RawLocation {
    return { name: `auth.${RouteNames.Auth}` }
  }

  public get buttonVariant (): string {
    return this.getConfigProperty('buttonVariant')
  }

  public get inputConfig (): AnyObject {
    return this.getConfigProperty<AnyObject>('input')
  }

  public get passwordInputConfig (): AnyObject {
    return this.getConfigProperty<AnyObject>('password')
  }

  public get passwordInputComponent (): string {
    return this.passwordInputConfig.simple ? 'UiInput' : 'UiPasswordInput'
  }

  /**
   * Login submit
   */
  public async resetPassword (): Promise<void> {
    const token = this.$route.query.token
    if (!token || typeof token !== 'string') {
      this.showToast('Token is not provided', 'danger')
      throw new Error('Token is not provided')
    }

    this.cleanError()

    return this.authControl.resetPassword({
      email: this.formData.email, newPassword: this.formData.newPassword, resetPasswordToken: token
    })
  }

  /**
   * Handles @success event of `Form`
   */
  public onFail (error: any): void {
    this.setError(error)
  }

  /**
   * Handles @success event of `Form`
   */
  public onSuccess (): void {
    this.showToast(this.$t('front.auth.organisms.resetPassword.youHaveChangedPassword')
      .toString(), 'success')

    const onSuccessStrategy = this.getConfigProperty<string>('onSuccessStrategy')
    this.isSuccessful = true

    switch (onSuccessStrategy) {
      case 'drawer':
        setTimeout(() => {
          this.openDrawerLogin()
          this.$router.push({ path: '/' })
        }, this.REDIRECT_PERIOD)
        break
      case 'banner':
        this.showBanner = true
        break
      default:
        this.openDrawerLogin()
        break
    }
  }

  /**
   * Open drawer modal
   */
  public openDrawerLogin (): void {
    if (!this.drawerService) {
      return
    }
    openAuthDrawer(this.drawerService)
  }

  /**
   * Cleans the error
   */
  protected cleanError (): void {
    this.error = null
  }
}

export default ResetPasswordForm
