
export const CONSENTS_REQUEST_VIEW_KEY = 'ConsentsRequestView'

export const CONSENTS_REQUEST_VIEW_CONFIG_MAP = {
  consents: {
    group: false,
    options: []
  },
  hasResellerIdField: false,
  validatorsMap: null
}
